<template>
    <div class="animated fadeIn">
        <b-card>
            <div class="display-table full-width mb-2">
                <strong class="card-title">
                    {{$route.meta.label}} <br>
                    <span class="text-muted">
                        Save media here, to save time uploading again every time
                        you need to arrange your website
                    </span>
                </strong>
                <b-button variant="primary" class="display-table-cell pull-right rounded" @click="$refs.uploadmedia.show()">
                    <i class="fa fa-plus" /> Upload Media
                </b-button>
            </div>
            <b-row style="padding:15px;width:100%;">
                <ResponseAlert ref="response" />
                <template v-if="config.total_data">
                    <b-col v-for="(row, key) in config.rows" :key="key" sm="3">
                        <div class="box-media">
                            <i title="Edit" class="fa fa-pencil btn-action-card" style="right:35px;" @click="$refs.uploadmedia.show(row.id)"></i>
                            <i title="Delete" class="fa fa-trash btn-action-card" @click="destroy(row.id)"></i>
                            <!-- <div class="media-image" :style="'background-image:url(' + row.file + ')'" /> -->
                            <a :href="row.file" target="_blank">
                                <div v-if="row.title.substring(row.title.lastIndexOf('.') + 1 ) === 'pdf'" class="media-image" style="background-image:url('/img/pdf-icon.png');">
                                    <span class="bg-white text-capitalize"> {{truncate(row.title,20)}} </span>
                                </div>
                                <div v-else-if="row.title.substring(row.title.lastIndexOf('.') + 1 ) === 'mp4'" class="media-image" style="background-image:url('/img/video-icon.png'); background-size:contain">
                                    <span class="bg-white text-capitalize"> {{truncate(row.title,20)}} </span>
                                </div>    
                                <div v-else class="media-image" :style="'background-image:url(' + row.file + ')'">
                                    <span class="bg-white text-capitalize"> {{truncate(row.title,20)}} </span>
                                </div>
                            </a>
                        </div>
                    </b-col>
                    <b-col sm="12" class="mt-3">
                        <b-row>
                        <b-col sm="6">
                            <div class="pull-left table-information mt-25">
                                <p>Showing {{config.page}} to {{config.total_page}} of {{config.total_data}}
                                    entries</p>
                            </div>
                        </b-col>
                        <b-col sm="6">
                            <div class="pull-right pagination mt-25">
                                <b-pagination @change="gotoPage" :total-rows="config.total_data" v-model="config.page"
                                    :per-page="config.per_page">
                                </b-pagination>
                            </div>
                        </b-col>
                        </b-row>
                    </b-col>
                </template>
                <b-col sm="12" v-else>
                    <b-alert show variant="light" class="text-center">
                        No media availabe
                    </b-alert>
                </b-col>
            </b-row>
        </b-card>
        <UploadMedia ref="uploadmedia" @getMedia="get" />
    </div>
</template>
<script>
    import table from '@/utils/table.js';
    import api from '@/utils/api.js';
    import {truncate} from '@/utils/helpers.js';
    import ResponseAlert from '@/components/response_alert';
    import UploadMedia from './upload';
    export default {
        name: 'ListMedia',
        components: {
            ResponseAlert,
            UploadMedia,
        },
        data() {
            return {
                truncate,
                config: {
                    uri: this.$route.meta.permission.link,
                    api: api.media,
                    rows: [],
                    total_data: 0,
                    total_page: 0,
                    per_page: 20,
                    page: 1,
                    orderby: "id",
                    sort: "desc",
                    search: {
                        key: ""
                    },
                },
            }
        },
        created() {
            const _ = this;
            _.get();
        },
        methods: {
            get() {
                table.get(this.config)
            },
            gotoPage(page) {
                table.gotoPage(this.config, page)
            },
            destroy(id) {
                const _ = this;
                _.$dialog.confirm('Are you sure want to delete this data?', {
                        "okText": "Yes",
                        "cancelText": "No"
                    })
                    .then(() => {
                        _.$axios.delete(`${api.media}/${id}`)
                            .then(res => {
                                _.$refs.response.setResponse(res.data.message, 'success');
                                _.get()
                            }).catch(error => {
							_.$refs.response.setResponse(error.response.data.message, 'danger')	
						})
                    })
            },
        },
    }
</script>