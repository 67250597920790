<template>
    <b-modal id="uploadmedia" size="xl" hide-footer hide-header>
        <form class="form-horizontal" @submit.prevent="doUpload">
            <b-card class="position-relative">
                <i class="fa fa-close btn-close-modal" @click="hide"></i>
                <h5 class="text-center">
                    {{ id === 0 ? 'Upload Media' : 'Edit Media' }}
                </h5>
                <p class="text-muted size12 text-center mb-4">
                    Upload media so that it can be used without having to re-upload
                </p>
                <b-row>
					<ResponseAlert ref="response" />
                    <b-col sm="12" class="mb-4" v-if="id === 0">
                        <div class="box-file" v-bind:class="{ 'is-invalid': errors.has('media')}">
                            <div class="image-input text-center">
                                <label for="file-input" class="width-auto">
                                    <input id="file-input" type="file" accept="image/png, image/gif, image/jpeg, image/jpg, image/ico, video/mp4, video/x-m4v, video/*, application/pdf"
                                        v-on:change="addMedia" hidden />
                                    <i class="fa fa-upload"></i> Choose File
                                </label>
                                <input type="hidden" v-model="models.file" v-validate="'required'" data-vv-name="media"/>
                            </div>
                        </div>
                    </b-col>
                    <template v-if="models.file">
                        <b-col sm="5">
                            <div class="border-image overflow-auto">
                                <b-img v-if="models.title.substring(models.title.lastIndexOf('.') + 1 ) === 'pdf'" 
                                    src="/img/pdf-icon.png"/>
                                <video v-else-if="models.title.substring(models.title.lastIndexOf('.') + 1 ) === 'mp4'" 
                                    :src="getUrlOfFile(models.file)" controls class="w-100">
                                    Your browser does not support the video tag.
                                </video>
                                <b-img v-else :src="getUrlOfFile(models.file)"/>
                            </div>
                        </b-col>
                        <b-col sm="7">
                            <b-form-group v-bind:class="{ 'is-invalid': errors.has('title')}" label="Title">
                                <b-form-input v-model="models.title" v-validate="'required'" data-vv-name="title">
                                </b-form-input>
                                <div v-if="errors.has('title')">
                                    <p class="text-danger">{{ errors.first('title') }}</p>
                                </div>
                            </b-form-group>
                            <b-form-group label="Description">
									<b-form-textarea
										v-model="models.description"
										rows="3"
										max-rows="6">
									</b-form-textarea>
							</b-form-group>
                        </b-col>
                    </template>
                </b-row>
                <template #footer>
                    <b-button type="submit" variant="primary" class="btn-min-width rounded float-right size13">
                        Save
                    </b-button>
                    <b-button type="button" variant="light" class="btn-min-width rounded float-right size13"
                        @click="hide">
                        Cancel
                    </b-button>
                </template>
            </b-card>
        </form>
    </b-modal>
</template>
<style>
    .card-footer{
        border-top: 0;
    }
</style>
<script>
    import api from '@/utils/api.js';
	import ResponseAlert from '@/components/response_alert';
    import { getUrlOfFile } from '@/utils/helpers.js';
    export default {
        name: 'UploadMedia',
		components: {
			ResponseAlert,
		},
        data() {
            return {
                id: 0,
                models: {
                    title: "",
                    description: "",
                    status: "active",
                    file: ""
                },
                vars: {
                    FormData: new FormData(),
                }
            }
        },
        mounted() {
            
        },
        methods: {
            show(id = 0) {
                const _ = this;
                _.reset();
                _.id = id;
                _.getById();
                _.$bvModal.show('uploadmedia');
            },
            hide() {
                this.$bvModal.hide('uploadmedia')
            },
            getById() {
				const _ = this;
				if (_.id) {
					_.$axios.get(`${api.media}/${_.id}`)
						.then(res => {
							const { data } = res.data;
							_.models.title = data.title;
							_.models.description = data.description;
							_.models.status = data.status;
							_.models.file = data.file;
						}).catch(() => _.$router.push({
							name: 'page404'
						}));
				}
			},
            addMedia(event) {
                const _ = this;
                _.models.file = event.target.files[0];
                _.models.title = _.models.file.name;
            },
            getUrlOfFile(file) {
				return getUrlOfFile(file);
			},
            reset(){
                this.models = {
                    title: "",
                    status: "active",
                    description: "",
                    file: ""
                }
            },
            doUpload() {
                const _ = this;
                _.vars.FormData = new FormData();
                for (const i in _.models) {
                    _.vars.FormData.append(i, _.models[i]);
                }
                _.$validator.validate().then(valid => {
                    if (valid) {
                        let request = "";
						if (_.id === 0) {
							request = _.$axios.post(api.media, _.vars.FormData)
						} else {
							request = _.$axios.put(`${api.media}/${_.id}`, _.models);
						}
						request
                            .then(() => {
                                _.$emit('getMedia');
                                _.reset();
                                _.hide();
                            })
                            .catch(error => _.$refs.response.setResponse(error.response.data.message,
                            'danger'));
                    } else {
                        window.scrollTo(0, 0);
                    }
                });
            },
        },
    }
</script>